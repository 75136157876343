import React from "react";
import AboutMelodi from "./Sections/AboutMelodi";
import MelodiWorks from "./Sections/MelodiWorks";
import MelodiFeatures from "./Sections/MelodiFeatures";
import "./Melodi.css";
import MelodiTarget from "./Sections/MelodiTarget";
import ContactUs from "../../../Common/ContactUs/ContactUs";

const Melodi = () => {
  return (
    <div className="container-mw">
      <AboutMelodi />
      <MelodiWorks />
      <MelodiFeatures />
      <MelodiTarget />
      <ContactUs />
    </div>
  );
};

export default Melodi;
