import React from "react";
import TextCapsules from "./TextCapsules";
import "./Components.css";

const FlexContainer = ({ format, src, tag, title, glow, desc, btn, goto }) => {
  return (
    <div className="flex-container">
      <div className="flex-container-text">
        <TextCapsules text={tag} />
        <div className="heading1 flex-container-title">
          {title}
          <span className="text-gradient">{glow}</span>
        </div>
        <div className="paragraph1 flex-container-desc">{desc}</div>
        {btn ? (
          <div className="flex-container-btn">
            <TextCapsules
              text={btn}
              textColor={"white"}
              bgColor={"#2D2D2D"}
              showArrow={true}
              isClickable={true}
              href={goto}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="flex-container-file">
        {format === "video" ? (
          <video src={src} autoPlay loop muted></video>
        ) : (
          <img src={src} alt="" />
        )}
      </div>
    </div>
  );
};

export default FlexContainer;
