import React from "react";
import "./StaticContent.css";

const PrivacyPolicy = () => {
  return (
    <div className="static-content container">
      <div className="static-content-title heading1">Privacy Policy</div>
      <div className="horizontal-line"></div>
      <div className="static-text">
        <br />
        <h2>Introduction</h2>
        <br />
        <p>
          This Privacy Policy governs the manner in which sisdea.com (“we” or
          “us”) collects, uses, maintains, and discloses information collected
          from users (referred to as “you” or “users”) of the sisdea.com website
          (“Website”). This policy applies to the Website and all products and
          services offered by sisdea.com.
        </p>

        <br />
        <h2>Information Collection and Use</h2>
        <br />
        <p>
          We may collect personal identification information from users in a
          variety of ways, including but not limited to when users visit our
          Website, register on the Website, place an order, subscribe to the
          newsletter, respond to a survey, fill out a form, and in connection
          with other activities, services, features or resources we make
          available on our Website. Users may be asked for their name, email
          address, mailing address, phone number, and payment information. We
          will collect personal identification information from users only if
          they voluntarily submit such information to us. Users can always
          refuse to supply personally identification information, except that it
          may prevent them from engaging in certain Website-related activities.
        </p>

        <br />
        <h2>Data Storage and Sharing</h2>
        <br />
        <p>
          We understand the importance of data privacy and security. We store
          and share the data collected from users in a secure manner, following
          industry best practices and implementing appropriate technical and
          organizational measures to protect against unauthorized access,
          alteration, disclosure, or destruction of your personal information
          stored on our Website.
        </p>

        <br />
        <h2>Third-Party Processing and Advertising</h2>
        <br />
        <p>
          We do not allow third-party vendors to process data collected on our
          Website. However, we may engage third-party services for payment
          processing. These third-party payment processors have their own
          privacy policies in place, and we encourage you to review their
          policies before providing your personal information.
        </p>

        <br />
        <h2>Children’s Privacy</h2>
        <br />
        <p>
          While our Website may be accessible to children, it is not
          specifically targeted towards them. We do not knowingly collect
          personal information from children under the age of 13. If you are a
          parent or guardian and you believe that your child has provided us
          with personal information, please contact us immediately. We will
          promptly remove such information from our records.
        </p>

        <br />
        <h2>User Rights</h2>
        <br />
        <p>
          We respect your privacy rights and provide you with the ability to
          access, rectify, erase, or object to the processing of your personal
          data. If you would like to exercise any of these rights or have any
          questions regarding the processing of your personal information,
          please contact us using the contact information provided at the end of
          this Privacy Policy.
        </p>

        <br />
        <h2>Changes to This Privacy Policy</h2>
        <br />
        <p>
          We reserve the right to update or change our Privacy Policy at any
          time. Any changes to the policy will be reflected on this page. We
          encourage users to check this page periodically for any updates. Your
          continued use of the Website after any changes to the Privacy Policy
          will signify your acceptance of those changes.
        </p>

        <br />
        <h2>Contact Us</h2>
        <br />
        <p>
          If you have any questions or concerns regarding this Privacy Policy or
          the practices of this Website, please contact us at:
        </p>
        <br />
        <p>
          Email: <a href="mailto:info@sisdea.com">info@sisdea.com</a>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
