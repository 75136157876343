import React from "react";
import FlexContainer from "../../../Common/Components/FlexContainer";
import aboutUs from "../../../assets/about-us.jpg";

const About = () => {
  return (
    <div className="container about">
      <FlexContainer
        src={aboutUs}
        tag={"About Us"}
        title={"SISDEA - A Confluence of "}
        glow={"AI and IoT Innovation"}
        desc={
          "At SISDEA, AI isn't just a buzzword—It's the core of everything we do, and IoT is no exception. Our leaders are pioneers in AI, IoT, and Wireless, with extensive experience in engineering world-class products and solutions that have scaled to millions in the global market. With numerous patents, innovations, and successful products to their credit, our leaders continue their passionate journey to bring impactful ideas to life through new products and solutions. Some of these innovations are showcased on this site, while others are brewing in our R&D lab. Stay tuned!"
        }
      />
    </div>
  );
};

export default About;
