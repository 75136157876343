import React from "react";
import FlexContainer from "../../../../Common/Components/FlexContainer";
import Target from "../../../../assets/target-melodi.jpg";

const MelodiTarget = () => {
  return (
    <div className="featured container set-order melodi-target">
      <FlexContainer
        tag={"Target Industries for MeLoDi"}
        title={"Versatile Tool for "}
        glow={"all Domains!"}
        desc={
          <>
            <p>
              MeLoDi is engineered to cater to a wide range of industries,
              offering log analysis solutions that meet the unique demands of
              each sector. The primary target industries include:
            </p>
            <br />
            <p>
              <strong>Telecom and Datacom Industry:</strong> Streamline log
              management and generate root cause analysis for any type of
              failure with 100% automation for any Telecom and Datacom products
              like NW equipment, wireless devices, terminals, networking
              products like routers, switches, etc.
            </p>
            <br />
            <p>
              <strong>Semiconductor Industry:</strong> Get SoCs and Reference
              Designs out quickly with the power of MeLoDi which for all phases
              of product development and for SW as well as HW!
            </p>
            <br />
            <p>
              <strong>Servers and Data Centers:</strong> With the amount of
              traffic that is generated towards the new-age servers and data
              centers, It's unimaginable to operate without automated log
              analysis, and MeLoDi is the top choice for best-in-class log
              analysis automation!
            </p>
            <br />
            <p>
              <strong>Embedded Systems, IoT, Cloud, Automotive:</strong> With
              domain-agnostic Gen AI that is at the heart of MeLoDi, it is an
              endless list of domains that can benefit with the power of its
              usage!
            </p>
            <br />
            <p>
              MeLoDi’s flexibility and robust capabilities make it an
              indispensable tool for any industry looking to leverage AI for
              superior log analysis and system performance.
            </p>
          </>
        }
        src={Target}
      />
    </div>
  );
};

export default MelodiTarget;
