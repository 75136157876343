import React from "react";
import About from "./Sections/About";
import "./AboutUs.css";
import CoreValues from "./Sections/CoreValues";
import ContactUs from "../../Common/ContactUs/ContactUs";

const AboutUs = () => {
  return (
    <div className="container-mw">
      <About />
      <CoreValues />
      <ContactUs />
    </div>
  );
};

export default AboutUs;
