import React from "react";
import s from "../../../../assets/s-icon.png";
import user from "../../../../assets/user-icon.png";
import analyze from "../../../../assets/analyze-icon.png";
import upload from "../../../../assets/upload-icon.png";
import banner from "../../../../assets/banner-melodi-features.png";
import Banner from "../../../../Common/Components/Banner";

const MelodiFeatures = () => {
  return (
    <div className="container">
      <div style={{ margin: "68px 0" }}>
        <Banner
          tag={"Features"}
          title={"Powerful Capabilities for "}
          glow={"Comprehensive Log Analysis"}
          src={banner}
        />
      </div>
      <div className="flex-container2-grid features-grid">
        <div className="flex-container2-grid-item">
          <div className="grid-icon">
            <img src={analyze} alt="" />
          </div>
          <div>
            <div className="heading2">{"Tuning the MeLoDi"}</div>
            <div className="paragraph1" style={{ marginTop: 10 }}>
              {
                "Just upload the log files corresponding to the successful (pass) scenarios from any type of testing - Unit Test, Integration Test, Lab Test, Field Test, Certification Test, etc., And let MeLoDi tune (train) itself! Logs from any domain, in any format! No Configuration, No Preprocessing, No Clean-up of logs. Just one click training!"
              }
            </div>
          </div>
        </div>
        <div className="flex-container2-grid-item">
          <div className="grid-icon">
            <img src={upload} alt="" />
          </div>
          <div>
            <div className="heading2">{"Playing the MeLoDi"}</div>
            <div className="paragraph1" style={{ marginTop: 10 }}>
              {
                "Upload the logs files from a failed test case or failure scenarios from field test or deployment and let MeLoDi bring out the root cause of failure with just a single click."
              }
            </div>
          </div>
        </div>{" "}
        <div className="flex-container2-grid-item">
          <div className="grid-icon">
            <img src={s} alt="" />
          </div>
          <div>
            <div className="heading2">{"Powered by SISDEA LLM"}</div>
            <div className="paragraph1" style={{ marginTop: 10 }}>
              {
                "At SISDEA our AI experts created a large language model that is purpose-built for log analysis. Small enough to be trained in a few hours on low-end GPUs! Start analyzing logs within hours of installing MeLoDi!"
              }
            </div>
          </div>
        </div>
        <div className="flex-container2-grid-item">
          <div className="grid-icon">
            <img src={user} alt="" />
          </div>
          <div>
            <div className="heading2">{"User-friendly and Feature Rich"}</div>
            <div className="paragraph1" style={{ marginTop: 10 }}>
              {
                "Going beyond AI, MeLoDi provides one-stop log management. Browse through specific logs, archive, and store logs, generate graphical insights, create dashboards, generate analysis summaries, and many more features all bundled into a single tool!"
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MelodiFeatures;
