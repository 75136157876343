import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import TextCapsules from "../Components/TextCapsules";
import { useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const getInitialRoute = () => {
    switch (location.pathname) {
      case "/home":
        return "home";
      case "/aboutus":
        return "aboutus";
      case "/melodi":
        return "our-product";
      case "/aitutor":
        return "our-product";
      case "/fault-detection":
        return "our-product";
      default:
        return "home";
    }
  };
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [lastClicked, setLastClicked] = useState(getInitialRoute());
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const toggleNav = () => {
    const hamburgerMenu = document.querySelector(".hamburger-menu");
    hamburgerMenu.classList.toggle("change");
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <div className="header">
      <div className="navbar navbar-mobile container-mw container-pd">
        <div className="navs">
          <div className="logo-text text-gradient">
            {" "}
            <Link
              to="/"
              onClick={() => {
                setLastClicked("home");
              }}
            >
              SISDEA
            </Link>
          </div>
          <div className="nav-items">
            <div
              className={`nav-item header-text ${
                lastClicked === "home" ? "active" : ""
              }`}
            >
              <Link
                to="/"
                onClick={() => {
                  setLastClicked("home");
                }}
              >
                Home
              </Link>
            </div>
            <div
              className={`nav-item header-text ${
                lastClicked === "aboutus" ? "active" : ""
              }`}
            >
              <Link
                to="/aboutus"
                onClick={() => {
                  setLastClicked("aboutus");
                }}
              >
                About Us
              </Link>
            </div>
            <div
              className={`nav-item header-text ${
                lastClicked === "our-product" ? "active" : ""
              }`}
              onMouseEnter={toggleDropdown}
              onMouseLeave={toggleDropdown}
              onClick={toggleDropdown}
            >
              Our Product
              {isDropdownOpen && (
                <div className="dropdown-content">
                  <div className="dropdown-item">
                    <Link
                      to="/melodi"
                      onClick={() => {
                        setLastClicked("our-product");
                      }}
                    >
                      MeLoDi
                    </Link>
                  </div>
                  <div className="dropdown-item">
                    {" "}
                    <Link
                      to="/aitutor"
                      onClick={() => {
                        setLastClicked("our-product");
                      }}
                    >
                      AI Tutor
                    </Link>
                  </div>
                  <div className="dropdown-item">
                    {" "}
                    <Link
                      to="/fault-detection"
                      onClick={() => {
                        setLastClicked("our-product");
                      }}
                    >
                      Fault Detection
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="hamburger-menu" onClick={toggleNav}>
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
        </div>
        <TextCapsules
          text={"Contact us"}
          textColor={"white"}
          bgColor={"#2D2D2D"}
          isClickable={true}
          href="#contact-us"
        />
        {mobileMenuOpen ? (
          <div className="nav-items-mob">
            <div
              className={`nav-item header-text ${
                lastClicked === "home" ? "active" : ""
              }`}
            >
              <Link
                to="/"
                onClick={() => {
                  toggleNav();
                  setLastClicked("home");
                }}
              >
                Home
              </Link>
            </div>
            <div
              className={`nav-item header-text ${
                lastClicked === "aboutus" ? "active" : ""
              }`}
            >
              <Link
                to="/aboutus"
                onClick={() => {
                  toggleNav();
                  setLastClicked("aboutus");
                }}
              >
                About Us
              </Link>
            </div>
            <div
              className={`nav-item header-text ${
                lastClicked === "Melodi" ? "active" : ""
              }`}
            >
              <Link
                to="/melodi"
                onClick={() => {
                  toggleNav();
                  setLastClicked("Melodi");
                }}
              >
                Melodi
              </Link>
            </div>
            <div
              className={`nav-item header-text ${
                lastClicked === "AI Tutor" ? "active" : ""
              }`}
            >
              <Link
                to="/aitutor"
                onClick={() => {
                  toggleNav();
                  setLastClicked("AI Tutor");
                }}
              >
                AI Tutor
              </Link>
            </div>
            <div
              className={`nav-item header-text ${
                lastClicked === "fault-detection" ? "active" : ""
              }`}
            >
              <Link
                to="/fault-detection"
                onClick={() => {
                  toggleNav();
                  setLastClicked("fault-detection");
                }}
              >
                Fault-Detection
              </Link>
            </div>
            <div
              onClick={() => {
                toggleNav();
              }}
            >
              <TextCapsules
                text={"Contact us"}
                textColor={"white"}
                bgColor={"#2D2D2D"}
                isClickable={true}
                href="#contact-us"
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Navbar;
