import React, { useState } from "react";
import TextCapsules from "../Components/TextCapsules";
import "./ContactUs.css";

const ContactUs = () => {
  const [showSuccessMail, setShowSuccessMail] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (e.target.checkValidity()) {
      setShowSuccessMail(true);
      // setTimeout(() => {
      //   setShowSuccessMail(false); 
      // }, 3000);
    } else {
      alert("Please fill out the form correctly.");
    }
  };

  return (
    <div className="contact-us container container-mw" id="contact-us">
      <div className="contact-us-form">
        <div className="contact-panel">
          <TextCapsules text={"Contact Us"} />
          <div className="heading1">
            Connect with{" "}
            <span className="text-gradient"> AI and IoT experts</span>
          </div>
          <div
            className="paragraph1"
            style={{ marginTop: "8px", color: "#666" }}
          >
            Reach out to us for any query or doubts
          </div>
          <div className="email-to">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  d="M3.50065 3.5H24.5007C24.8101 3.5 25.1068 3.62292 25.3256 3.84171C25.5444 4.0605 25.6673 4.35725 25.6673 4.66667V23.3333C25.6673 23.6428 25.5444 23.9395 25.3256 24.1583C25.1068 24.3771 24.8101 24.5 24.5007 24.5H3.50065C3.19123 24.5 2.89449 24.3771 2.67569 24.1583C2.4569 23.9395 2.33398 23.6428 2.33398 23.3333V4.66667C2.33398 4.35725 2.4569 4.0605 2.67569 3.84171C2.89449 3.62292 3.19123 3.5 3.50065 3.5ZM14.0707 13.6302L6.58998 7.27767L5.07915 9.05567L14.0858 16.7032L22.9303 9.04983L21.4043 7.28467L14.0707 13.6302Z"
                  fill="url(#paint0_linear_703_4123)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_703_4123"
                    x1="3.72532"
                    y1="14"
                    x2="23.4453"
                    y2="14.2214"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0.159104" stop-color="#35C5EE" />
                    <stop offset="0.868555" stop-color="#1E7188" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div
              className="form-head1"
              style={{ marginLeft: "12px", marginRight: "4px" }}
            >
              Email:
            </div>
            <div className="paragraph1">admin@sisdea.ai</div>
          </div>
        </div>
        <div className="input-panel">
          <form onSubmit={handleSubmit} className="contact-form">
            <div className="form-group paragraph1">
              <label className="form-head1" htmlFor="fname">
                Full Name
              </label>
              <input
                className="paragraph1"
                type="text"
                pattern="[A-Za-z\s]+"
                id="fname"
                placeholder="Enter full name"
                required
                maxLength="100"
              />
            </div>
            <div className="form-group paragraph1">
              <label className="form-head1" htmlFor="email">
                Email
              </label>
              <input
                className="paragraph1"
                type="email"
                maxLength="100"
                id="email"
                placeholder="Enter your email address"
                required
              />
            </div>
            <div className="form-group paragraph1">
              <label className="form-head1" htmlFor="topic">
                Write a topic
              </label>
              <input
                className="paragraph1"
                type="text"
                maxLength="100"
                id="topic"
                placeholder="Write a topic here"
                required
              />
            </div>
            <div className="form-group paragraph1">
              <label className="form-head1" htmlFor="message">
                Message
              </label>
              <textarea
                className="paragraph1"
                id="message"
                placeholder="Write your message..."
                required
                maxLength="1500"
              ></textarea>
            </div>
            <button type="submit" className="submit-button reset-button">
              <TextCapsules
                showArrow={true}
                text={"Submit"}
                textColor={"white"}
                bgColor={"#2D2D2D"}
                isClickable={false}
              />
            </button>
          </form>
          {showSuccessMail ? (
            <div className="prompt">
              <div className="prompt-msg paragraph1">
                Thank you for contacting us. We received your message! Will get
                back to you shortly!
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
