import React from "react";
import "./StaticContent.css";
const TandC = () => {
  return (
    <>
      <div className="static-content container">
        <div className="static-content-title heading1">Terms & Conditions</div>
        <div className="horizontal-line"></div>
        <div className="static-text">
          <br />
          <p>Last updated: June 28, 2023</p>
          <br />
          <h2>1. Introduction</h2>
          <br />
          <p>
            These Terms and conditions apply to this website and to the
            transactions related to our products and services. You may be bound
            by additional contracts related to your relationship with us or any
            products or services that you receive from us. If any provisions of
            the additional contracts conflict with any provisions of these
            Terms, the provisions of these additional contracts will control and
            prevail.
          </p>
          <br />
          <h2>2. Binding</h2>
          <br />
          <p>
            By registering with, accessing, or otherwise using this website, you
            hereby agree to be bound by these Terms and conditions set forth
            below. The mere use of this website implies the knowledge and
            acceptance of these Terms and conditions. In some particular cases,
            we can also ask you to explicitly agree.
          </p>
          <br />
          <h2>3. Intellectual Property</h2>
          <br />
          <p>
            We or our licensors own and control all of the copyright and other
            intellectual property rights in the website and the data,
            information, and other resources displayed by or accessible within
            the website.
          </p>
          <br />
          <h3>3.1 Creative Commons</h3>
          <br />
          <p>
            The content on this website is available under a License, unless
            specified otherwise.
          </p>
          <br />
          <h2>4. Third-party Property</h2>
          <br />
          <p>
            Our website may include hyperlinks or other references to other
            party’s websites. We do not monitor or review the content of other
            party’s websites which are linked to from this website. Products or
            services offered by other websites shall be subject to the
            applicable Terms and Conditions of those third parties. Opinions
            expressed or material appearing on those websites are not
            necessarily shared or endorsed by us.
          </p>
          <br />
          <p>
            We will not be responsible for any privacy practices or content of
            these sites. You bear all risks associated with the use of these
            websites and any related third-party services. We will not accept
            any responsibility for any loss or damage in whatever manner,
            however caused, resulting from your disclosure to third parties of
            personal information.
          </p>
          <br />
          <h2>5. Responsible Use</h2>
          <br />
          <p>
            By visiting our website, you agree to use it only for the purposes
            intended and as permitted by these Terms, any additional contracts
            with us, and applicable laws, regulations, and generally accepted
            online practices and industry guidelines. You must not use our
            website or services to use, publish or distribute any material which
            consists of (or is linked to) malicious computer software; use data
            collected from our website for any direct marketing activity, or
            conduct any systematic or automated data collection activities on or
            in relation to our website.
          </p>
          <br />
          <p>
            Engaging in any activity that causes, or may cause, damage to the
            website or that interferes with the performance, availability, or
            accessibility of the website is strictly prohibited.
          </p>
          <br />
          <h2>6. Refund and Return Policy</h2>
          <br />
          <h3>6.1 Right of Withdrawal</h3>
          <br />
          <p>
            You have the right to withdraw from this contract within 14 days
            without giving any reason.
          </p>
          <br />
          <p>
            To exercise the right of withdrawal, you must inform us of your
            decision to withdraw from this contract by an unequivocal statement
            (for example a letter sent by post, fax, or email). Our contact
            details can be found below. You may use the attached model
            withdrawal form, but it is not obligatory.
          </p>
          <br />
          <p>
            You can also electronically fill in and submit the model withdrawal
            form or any other unequivocal statement on our website.
          </p>
          <br />
          <p>
            If you use this option, we will communicate to you an acknowledgment
            of receipt of such a withdrawal on a durable medium (for example by
            email) without delay.
          </p>
          <br />
          <p>
            To meet the withdrawal deadline, it is sufficient for you to send
            your communication concerning your exercise of the right of
            withdrawal before the withdrawal period has expired.
          </p>
          <br />
          <h3>6.2 Effects of Withdrawal</h3>
          <br />
          <p>
            If you withdraw from this contract, we shall reimburse you all
            payments received from you, including the costs of delivery (with
            the exception of the supplementary costs resulting from your choice
            of a type of delivery other than the least expensive type of
            standard delivery offered by us), without undue delay and in any
            event not later than 14 days from the day on which we are informed
            about your decision to withdraw from this contract. We will carry
            out such reimbursement using the same means of payment as you used
            for the initial transaction unless you have expressly agreed
            otherwise; in any event, you will not incur any fees as a result of
            such reimbursement.
          </p>
          <br />
          <p>
            You are only liable for any diminished value of the goods resulting
            from the handling other than what is necessary to establish the
            nature, characteristics, and functioning of the goods.
          </p>
          <br />
          <p>
            Please note that there are some legal exceptions to the right to
            withdraw, and some items can therefore not be returned or exchanged.
            We will let you know if this applies in your particular case.
          </p>
          <br />
          <h2>7. Idea Submission</h2>
          <br />
          <p>
            Do not submit any ideas, inventions, works of authorship, or other
            information that can be considered your own intellectual property
            that you would like to present to us unless we have first signed an
            agreement regarding the intellectual property or a non-disclosure
            agreement. If you disclose it to us absent such written agreement,
            you grant to us a worldwide, irrevocable, non-exclusive,
            royalty-free license to use, reproduce, store, adapt, publish,
            translate and distribute your content in any existing or future
            media.
          </p>
          <br />
          <h2>8. Termination of Use</h2>
          <br />
          <p>
            We may, in our sole discretion, at any time modify or discontinue
            access to, temporarily or permanently, the website or any Service
            thereon. You agree that we will not be liable to you or any third
            party for any such modification, suspension or discontinuance of
            your access to, or use of, the website or any content that you may
            have shared on the website. You will not be entitled to any
            compensation or other payment, even if certain features, settings,
            and/or any Content you have contributed or have come to rely on, are
            permanently lost. You must not circumvent or bypass, or attempt to
            circumvent or bypass, any access restriction measures on our
            website.
          </p>
          <br />
          <h2>9. Warranties and Liability</h2>
          <br />
          <p>
            Nothing in this section will limit or exclude any warranty implied
            by law that it would be unlawful to limit or to exclude. This
            website and all content on the website are provided on an “as is”
            and “as available” basis and may include inaccuracies or
            typographical errors. We expressly disclaim all warranties of any
            kind, whether express or implied, as to the availability, accuracy,
            or completeness of the Content. We make no warranty that:
          </p>
          <br />
          The following provisions of this section will apply to the maximum
          extent permitted by applicable law and will not limit or exclude our
          liability in respect of any matter which it would be unlawful or
          illegal for us to limit or to exclude our liability.
          <br />
          <br />
          In no event will we be liable for any direct or indirect damages
          (including any damages for loss of profits or revenue, loss or
          corruption of data, software or database, or loss of or harm to
          property or data) incurred by you or any third party, arising from
          your access to, or use of, our website.
          <br />
          <br />
          <h2>10. Privacy</h2>
          <br />
          <p>
            To access our website and/or services, you may be required to
            provide certain information about yourself as part of the
            registration process. You agree that any information you provide
            will always be accurate, correct, and up to date.
          </p>
          <br />
          <p>
            We have developed a policy to address any privacy concerns you may
            have. For more information, please see our Privacy Statement and our
            Cookie Policy.
          </p>
          <br />
          <h2>11. Export Restrictions / Legal Compliance</h2>
          <br />
          <p>
            Access to the website from territories or countries where the
            Content or purchase of the products or Services sold on the website
            is illegal is prohibited. You may not use this website in violation
            of export laws and regulations of [Your Country].
          </p>
          <br />
          <h2>12. Assignment</h2>
          <br />
          <p>
            You may not assign, transfer or sub-contract any of your rights
            and/or obligations under these Terms and conditions, in whole or in
            part, to any third party without our prior written consent. Any
            purported assignment in violation of this Section will be null and
            void.
          </p>
          <br />
          <h2>13. Breaches of These Terms and Conditions</h2>
          <br />
          <p>
            Without prejudice to our other rights under these Terms and
            Conditions, if you breach these Terms and Conditions in any way, we
            may take such action as we deem appropriate to deal with the breach,
            including temporarily or permanently suspending your access to the
            website, contacting your internet service provider to request that
            they block your access to the website, and/or commence legal action
            against you.
          </p>
          <br />
          <h2>14. Indemnification</h2>
          <br />
          <p>
            You agree to indemnify, defend and hold us harmless, from and
            against any and all claims, liabilities, damages, losses, and
            expenses, relating to your violation of these Terms and conditions,
            and applicable laws, including intellectual property rights and
            privacy rights. You will promptly reimburse us for our damages,
            losses, costs, and expenses relating to or arising out of such
            claims.
          </p>
          <br />
          <h2>15. Waiver</h2>
          <br />
          <p>
            Failure to enforce any of the provisions set out in these Terms and
            Conditions and any Agreement, or failure to exercise any option to
            terminate, shall not be construed as a waiver of such provisions and
            shall not affect the validity of these Terms and Conditions or of
            any Agreement or any part thereof, or the right thereafter to
            enforce each and every provision.
          </p>
          <br />
          <h2>16. Language</h2>
          <br />
          <p>
            These Terms and Conditions will be interpreted and construed
            exclusively in English. All notices and correspondence will be
            written exclusively in that language.
          </p>
          <br />
          <h2>17. Entire Agreement</h2>
          <br />
          <p>
            These Terms and Conditions, together with our privacy statement and
            cookie policy, constitute the entire agreement between you and
            [Company Name] in relation to your use of this website.
          </p>
          <br />
          <h2>18. Choice of Law and Jurisdiction</h2>
          <br />
          <p>
            These Terms and Conditions shall be governed by the laws of . Any
            disputes relating to these Terms and Conditions shall be subject to
            the jurisdiction of the courts of . If any part or provision of
            these Terms and Conditions is found by a court or other authority to
            be invalid and/or unenforceable under applicable law, such part or
            provision will be modified, deleted and/or enforced to the maximum
            extent permissible so as to give effect to the intent of these Terms
            and Conditions. The other provisions will not be affected.
          </p>
          <br />
          <h2>19. Contact information</h2>
          <br />
          <p>This website is owned and operated by .</p>
        </div>
      </div>
    </>
  );
};

export default TandC;
