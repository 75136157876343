import React from "react";
import TextCapsules from "./TextCapsules";

const Banner = ({ tag, title, glow, desc, src }) => {
  return (
    <div className="banner">
      <TextCapsules text={tag} />
      <div className="heading1">
        {title}
        <span className="text-gradient">{glow}</span>
      </div>
      <div className="banner-img" style={{ marginTop: 30 }}>
        <img src={src} alt="" />
      </div>
      <div className="paragraph1" style={{ marginTop: 30 }}>
        {desc}
      </div>
    </div>
  );
};

export default Banner;
