import React from "react";
import TextCapsules from "./TextCapsules";

const FlexContainer2 = ({ title, glow, tag, src, gridItems }) => {
  return (
    <div className="flex-container-2">
      <TextCapsules text={tag} />
      <div className="heading1">
        {title}
        <span className="text-gradient">{glow}</span>
      </div>
      <div className="flex-container2-flex">
        <div className="flex-container2-grid">
          {gridItems.map((item) => {
            return (
              <div className="flex-container2-grid-item">
                <div className="grid-icon">
                  <img src={item.src} alt="" />
                </div>
                <div>
                  <div className="heading2">{item.title}</div>
                  <div className="paragraph1" style={{ marginTop: 10 }}>
                    {item.desc}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex-container2-img">
          <img src={src} alt="" />
        </div>
      </div>
    </div>
  );
};

export default FlexContainer2;
