import React from "react";
import "./Components.css";
import arrow from "../../assets/arrow.svg";

const TextCapsules = ({
  text,
  textColor,
  bgColor,
  showArrow,
  isClickable,
  href,
}) => {
  const handleClick = (e) => {
    if (isClickable) {
      e.preventDefault();
      if (href === "#contact-us") {
        const targetElement = document.querySelector(href);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        window.location.href = href; // Changes the URL and reloads the page
      }
    }
  };

  return (
    <div
      className={`text-capsules ${isClickable ? "clickable" : ""}`}
      style={{ background: bgColor, borderColor: textColor }}
      onClick={handleClick}
    >
      <div className="capsules-text" style={{ color: textColor }}>
        {text}
      </div>
      {showArrow && (
        <div className="arrow-svg">
          <img src={arrow} alt="arrow" />
        </div>
      )}
    </div>
  );
};

export default TextCapsules;
