import React from "react";
import FlexContainer from "../../../../Common/Components/FlexContainer";
import aboutMelodi from "../../../../assets/about-melodi.png";

const AboutMelodi = () => {
  return (
    <div className="container about">
      <FlexContainer
        src={aboutMelodi}
        tag={"About MeLoDi"}
        title={"Transforming Log Analysis with "}
        glow={"Generative AI"}
        desc={
          "MeLoDi (Machine Learning for Log Data Insight) is SISDEA’s cutting-edge AI tool designed to change the way engineers analyze log data. Logs analysis is key to finding the root cause of bugs in the system in all phases of product development and consumes huge manpower even with conventional automation techniques. It is also essential for understanding the performance, security, and overall efficiency of the system. But with so much data to sift through, it can be tough to fix issues in a short time or to get useful insights from the logs. MeLoDi frees businesses from these burdens enabling them to spend time on developing new features or building new products. MeLoDi's AI models bring out the crisp and clear root cause behind the reported issues without any human effort!"
        }
      />
    </div>
  );
};

export default AboutMelodi;
