import React from "react";
import featuredRobo from "../../../assets/featured-robo.mp4";
import FlexContainer from "../../../Common/Components/FlexContainer";

const Featured = () => {
  return (
    <div className="featured container set-order container-mw">
      <FlexContainer
        tag={"Featured Project"}
        title={"Showcasing Our "}
        glow={"AI Excellence"}
        desc={
          "Explore some of our key solutions standout projects where SISDEA's cutting-edge AI solutions have significantly impacted."
        }
        btn={"Know More"}
        format={"video"}
        src={featuredRobo}
        goto={"/melodi"}
      />
    </div>
  );
};

export default Featured;
